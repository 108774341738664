import accounting from 'accounting'

export default class Utils {
  /**
   * Defers the execution until the windowMethod (for example jQuery) is loaded.
   * @param {method) method to execute when windowMethod is available.
   */
  static defer(windowMethod, method) {
    if (window[windowMethod]) {
      method()
    } else {
      setTimeout( () => {
        this.defer(windowMethod, method);
      }, 20)
    }
  }

  static getUrlParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&")
    let url = window.location.href
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    let results = regex.exec(url)

    if (!results)
      return null

    if (!results[2])
      return ''

    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  static detect(collection, id) {
    if (!collection)
      return {}

    if (collection.length == 0)
      return {}

    return collection.filter((el) =>
      el.id == id
    )[0]
  }

  static logError(message) {
    console.error(`\n\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!'\n[GY] ${message}\n!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!\n\n`)
  }

  static logNotice(message, type = "GY", data) {
    console.log(`%c[${type}] ${message}`, "color:green; background-color: yellow")
    if (data)
      console.log(JSON.stringify(data))
  }

  static checkForEmptyObject(object) {
    return Object.keys(object).length === 0 && object.constructor === Object
  }

  static currency_format(value) {
    let currency = window.current_currency

    let options = {
      symbol: currency.symbol,
      format: currency.symbol_first ? "%s%v" : "%v%s",
      decimal: currency.decimal_mark,
      thousand: currency.separator,
      precision: 2
    }

    return accounting.formatMoney(value, options)
  }

  static guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  static extractEmails(text) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
  }

  static simulateClick (el) {
    // Create our event (with options)
    let event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })

    // If cancelled, don't dispatch our event
    let canceled = !el.dispatchEvent(event)
  }

  static closest (el, predicate) {
    do if (predicate(el)) return el
    while (el = el && el.parentNode)
  }

  static hasClass(el, className) {
    if (el.classList)
      return el.classList.contains(className)
    else
      return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className)
  }
}
