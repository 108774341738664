import $ from 'jquery'
import { productInstance } from '../main'
import { store } from '../lib/store'

export default class Catalog {
  constructor() {
    this.bindLoadMore()
    this.bindFilters()
    this.checkIfLastPage()
  }

  checkIfLastPage() {
    if ($('.pagination').length > 0 && $('.pagination .next a').length == 0) {
      store.dispatch('triggerEvent', 'gy::catalog-last-page')
    }
  }

  bindLoadMore() {
    let $this = this

    if ($('.gy-load-more').length > 0) {
      $('.pagination').hide()

      $(document).on('click', '.gy-load-more', function(event) {
        event.preventDefault()
        let el  = $(this)
        let url = $('.pagination .next a').attr('href')

        // Prevent double clicking
        if (el.is("a[disabled]"))
          return false

        el.attr('disabled', 'disabled').addClass('loading')

        if (url) {
          $.ajax({
            url: url,
            data: {
              partially: true
            },
            dataType: 'script'
          }).done(response => {
            store.dispatch('triggerEvent', 'gy::catalog-paginate')

            productInstance.initializeAll()
            $this.checkIfLastPage()
            $('.pagination').hide()

            el.attr('disabled', false).removeClass('loading')
          })
        }
      })
    }
  }

  bindFilters() {
    let $this = this
    let selectors = '.gy-filters a, .gy-selected-filters a, .gy-sorting a, .gy-per a'

    if ($('.gy-filters').length > 0) {
      $(document).on('click', selectors, function(event) {
        event.preventDefault()
        let el  = $(this)
        let url = el.attr('href')

        if (url) {
          productInstance.clearApps()

          store.dispatch('triggerEvent', 'gy::catalog-visit-started')

          $.ajax({
            url: url,
            dataType: 'script'
          }).done(response => {
            if (!el.data('disable-push-state'))
              history.pushState(null, '', url)

            store.dispatch('triggerEvent', 'gy::catalog-visit-done')

            productInstance.initializeAll()
            $this.bindLoadMore()

            $this.checkIfLastPage()

            if ($('.gy-load-more').length > 0) {
              $('.pagination').hide()
            }
          })
        }
      })
    }
  }
}
