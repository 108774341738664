<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default Vue.component('option-variant-row', {
  props: {
    optionVariant: {
      default: () => {
        return {}
      },
      type: Object
    },
    chosenOptions: {
      default: undefined
    }
  },
  data() {
    return {
      quantity: 0
    }
  },
  computed: {
    code() {
      return this.selectedSku.barcode
    },
    price() {
      return this.selectedSku.price
    },
    rawPrice() {
      return this.selectedSku.raw_price
    },
    discountedPrice() {
      return this.selectedSku.discounted_price
    },
    rawDiscountedPrice() {
      return this.selectedSku.raw_discounted_price
    },
    offered() {
      return this.selectedSku.offered
    },
    selected() {
      let selected = Object.assign({}, this.$parent.$parent.selected)

      for (let key of Object.keys(selected)) {
        if (selected[key] == null || selected[key] == "") {
          selected[key] = this.optionVariant.id
        }
      }

      return selected
    },
    selectedSku() {
      let skus     = this.$parent.$parent.skus
      let sku      = {}

      for (let key of Object.keys(skus)) {
        if (key == Object.values(this.selected).join(",")) {
          sku = skus[key]
          break
        }
      }

      return sku
    },
    visible() {
      return this.selectedSku.barcode != undefined
    },
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    quantity: function(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$parent.updateTotal()
      }
    }
  },
  methods: {
    selectedOptionVariantForOption(option) {
      let optionVariantId = this.$parent.$parent.selected[option.id]

      if (optionVariantId) {
        for (let optionVariant of option.option_variants) {
          if (parseInt(optionVariant.id) == parseInt(optionVariantId)) {
            return optionVariant
          }
        }
      }
    }
  }
})
</script>