import Vue                      from 'vue'
import VueResource              from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store }                from '../lib/store'
import { EventBus }             from '../lib/event_bus.js'

import scroll from 'scroll'
var page = require('scroll-doc')()
var ease = require('ease-component')

import AddressSelect            from '../components/address_select.vue'
import CountrySelect            from '../components/country_select.vue'
import RegionSelect             from '../components/region_select.vue'
import VatOfficeSelect          from '../components/vat_office_select.vue'
import VatNumberField           from '../components/vat_number_field.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

export default new Vue({
  el: '#gy-checkout',
  store,
  components: {
    AddressSelect,
    CountrySelect,
    RegionSelect,
    VatOfficeSelect
  },
  data() {
    return {
      shippingMethods: [],
      paymentMethods: [],
      selectedPaymentMethod: null,
      selectedShippingMethod: null,
      invoiceSelected: false,
      sameAsBilling: true,
      billing_address: {},
      shipping_address: {}
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    billingCountry() {
      return (this.$store.state.billing_address || {}).country
    },
    billingRegion() {
      return (this.$store.state.billing_address || {}).region
    },
    shippingCountry() {
      return (this.$store.state.shipping_address || {}).country
    },
    shippingRegion() {
      return (this.$store.state.shipping_address || {}).region
    },
    cartEmpty() {
      return this.order.total_quantity == undefined || this.order.total_quantity < 1
    },
    invalidPaymentShippingMethod() {
      if (!this.order.validates_payment_and_shipping_methods) {
        this.scrollToError()
      }
      return null
    }
  },
  watch: {
    billingCountry: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshPaymentMethods()
      }
    },
    billingRegion: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshPaymentMethods()
      }
    },
    shippingCountry: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshShippingMethods()
      }
    },
    shippingRegion: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshShippingMethods()
      }
    },
    selectedPaymentMethod: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshShippingMethods()
      }
    },
    selectedShippingMethod: function(newValue, oldValue) {
      if (oldValue != newValue) {
        this.refreshPaymentMethods()
      }
    },
    invoiceSelected: function(newValue, oldValue) {
      if (oldValue != newValue) {
        if (this.$refs.invoice_radio && newValue == 'true') {
          this.$refs.invoice_radio.click()
        }
        else if (this.$refs.receipt_radio && newValue == 'false') {
          this.$refs.receipt_radio.click()
        }

        this.refreshPaymentMethods()
        this.refreshShippingMethods()
      }
    },
    'order.payment_method_id': function(newValue, OldValue) {
      this.selectedPaymentMethod = newValue
    },
    'order.shipping_method_id': function(newValue, OldValue) {
      this.selectedShippingMethod = newValue
    },
    'order.is_invoice': function(newValue, OldValue) {
      this.invoiceSelected = newValue

      if (this.$refs.invoice_radio && newValue) {
        this.$refs.invoice_radio.click()
      }
      else if (this.$refs.receipt_radio && !newValue) {
        this.$refs.receipt_radio.click()
      }
    },
    invalidPaymentShippingMethod() {
      if (!this.order.validates_payment_and_shipping_methods) {
        this.scrollToError()
      }
    }
  },
  mounted() {
    this.sameAsBilling = this.preCheckSameAsBilling()

    this.billing_address  = this.$store.state.billing_address
    this.shipping_address = this.$store.state.shipping_address

    EventBus.$on('checkout-address-selected', this.populateAddresses)
  },
  methods: {
    /*
     * Populates the address
     * @event - gy::checkout-address-populated is triggered when activated
     */
    populateAddresses() {
      this.billing_address  = this.$store.state.billing_address

      if (this.sameAsBilling) {
        this.shipping_address = this.billing_address
      }

      this.refreshPaymentMethods()

      this.$store.dispatch('triggerEvent', 'gy::checkout-address-populated')
    },

    /*
     * It refreshes payment methods.
     * @event - gy::payment-methods-loaded is triggered when activated
     */
    refreshPaymentMethods() {
      if (this.billingCountry != undefined) {
        this.$http.post(`${this.apiPath}/order/payment_methods`, { is_invoice: this.invoiceSelected, country_id: this.billingCountry.id, region_id: (this.billingRegion || {}).id, shipping_method_id: this.selectedShippingMethod } ).then(response => {
          this.paymentMethods = response.body.payment_methods
          this.$store.dispatch('triggerEvent', 'gy::payment-methods-loaded')

          this.preselectPaymentMethod()
        })
      }
    },

    /*
     * It refreshes shipping methods.
     * @event - gy::shipping-methods-loaded event is triggered when activated
     */
    refreshShippingMethods() {
      if (this.shippingCountry != undefined) {
        this.$http.post(`${this.apiPath}/order/shipping_methods`, { is_invoice: this.invoiceSelected, country_id: this.shippingCountry.id, region_id: (this.shippingRegion || {}).id, payment_method_id: this.selectedPaymentMethod } ).then(response => {
          this.shippingMethods = response.body.shipping_methods
          this.$store.dispatch('triggerEvent', 'gy::shipping-methods-loaded')

          this.preselectShippingMethod()
        })
      }
    },

    /*
     * Preselect shipping method if we have only one.
     */
    preselectShippingMethod() {
      if (this.shippingMethods.length == 1) {
        this.selectedShippingMethod = this.shippingMethods[0].id
      }
    },

    /*
     * Preselect payment method if we have only one.
     */
    preselectPaymentMethod() {
      if (this.paymentMethods.length == 1) {
        this.selectedPaymentMethod = this.paymentMethods[0].id
      }
    },

    preCheckSameAsBilling() {
      if (this.$el && this.$el.attributes) {
        let value = (this.$el.attributes.sameAsBilling || {}).value
        return value != "false"
      } else {
        return true
      }
    },

    /*
     * Scrolls to present error for ui purposes.
     * Uses offset taken from error element's data in order to adjust offset per project.
     */
    scrollToError() {
      let error = this.$refs.invalid_methods_error

      if (error) {
        let offset = parseInt(error.dataset.offset)
        let y = error.offsetTop - offset

        scroll.top(page, y, { duration: 1000, ease: ease.inOutSine })
      }
    }
  }
})
