<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import miniCart from '../apps/mini_cart'
import checkout from '../apps/checkout'

export default Vue.component('line-item', {
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    quantities() {
      return Array(this.item.max_quantity).fill().map((_, i) => i + 1)
    }
  },
  watch: {
    'item.quantity': function(newQuantity, oldQuantity) {
      if (newQuantity && newQuantity != oldQuantity) {
        this.updateQuantity()
      }
    }
  },
  methods: {
    /*
     * It deletes the line item from the order.
     * @event - gy::line-item-deleted event is triggered
     */
    deleteLineItem() {
      this.$http.delete(`${this.apiPath}/line_items/${this.item.id}`).then(response => {
        miniCart.loadOrder()

        checkout.refreshPaymentMethods()
        checkout.refreshShippingMethods()

        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-deleted', message: response.body.message, item: this.item })
      })
    },

    /*
     * It updates quantity for line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    updateQuantity() {
      this.$http.put(`${this.apiPath}/line_items/${this.item.id}`, { quantity: this.item.quantity }).then(response => {
        miniCart.loadOrder()

        checkout.refreshPaymentMethods()
        checkout.refreshShippingMethods()

        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-quantity-updated', message: response.body.message })
      })
    },

    /*
     * It increases by 1 the quantity of the line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    increaseQuantity() {
      if (this.item.quantity < this.item.max_quantity)
        this.item.quantity = this.item.quantity + 1
    },

    /*
     * It decreases by 1 the quantity of the line item.
     * @event - gy::line-item-quantity-updated event is triggered
     */
    decreaseQuantity() {
      if (this.item.quantity > 1)
        this.item.quantity = this.item.quantity - 1
    }

  },
  template: '#line-item-template'
})
</script>