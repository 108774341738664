<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import miniWishlist from '../apps/mini_wishlist'
import wishlist from '../apps/wishlist'
import miniCart from '../apps/mini_cart'
import Product  from './product.vue'

export default Vue.component('wishlist-item', {
  extends: Product,
  props: {
    item: {
      default: () => {
        return {}
      },
      type: undefined
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    computedProductId() {
      if (this.item != undefined) {
        return this.item.product_id
      }

      return undefined
    }
  },
  methods: {
    /*
     * It deletes the item from the wishlist.
     * @param {Object} item - the line item we wish to delete
     * @event - gy::wishlist-item-deleted is triggered
     */
    deleteWishlistItem() {
      this.$http.delete(`${this.apiPath}/wishlist_items/${this.item.id}`).then(response => {
        this._loadWishlistItems()
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-item-deleted', message: response.body.message })
      })
    },

    /*
     * It adds selected item to cart.
     * @param {Object} item - the line item we wish to add to cart
     * @event - gy::wishlist-added-to-cart is triggered
     */
    addToCart() {
      this.$http.post(`${this.apiPath}/wishlist_items/add_to_cart`, { product_id: this.item.product_id, options: this.item.options }).then(response => {
        miniCart.loadOrder()
        this._loadWishlistItems()
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-added-to-cart', message: response.body.message })
      })
    },

    /*
     * It loads the wishlist items per case.
     * @param {Object} item - the current line item
     */
    _loadWishlistItems() {
      this.item.isMiniWishlistItem ? miniWishlist.loadWishlistItems() : wishlist.loadWishlistItems(false)
    }
  }
})
</script>
