import { mapState, mapGetters } from 'vuex'

import miniCart from '../apps/mini_cart'

export default {
  computed: {
    ...mapState([
      'user',
      'wishlistItems'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      }

      return undefined
    },
    wishlistEmpty() {
      return this.wishlistItems == undefined || this.wishlistItems.length == 0
    },
    totalQuantity() {
      return (this.wishlistItems || {}).length || 0
    },
    signedIn() {
      return this.$store.state.user && this.$store.state.user.id > 0
    }
  },
  methods: {
    /*
     * Loads the order from the API. Its called once when app is mounted.
     * @param {Bolean} is_miniwishlist_item - Default value is true
     * @event - gy:wishlist-loaded event is triggered
     */
    loadWishlistItems(is_miniwishlist_item = true) {
      this.$http.get(`${this.apiPath}/wishlist_items`, { params: { versions: this.stringifiedVersions } }).then(response => {
        let wishlist_items = response.body.wishlist_items
        if (wishlist_items != undefined) {
          for (let item of wishlist_items){
            item.isMiniWishlistItem = is_miniwishlist_item
          }

          this.$store.state.wishlistItems = wishlist_items
          this.$store.dispatch('triggerEvent', 'gy::wishlist-loaded')
        }
      })
    },

    /*
     * It adds all items to cart.
     * @event - gy::wishlist-added-all-to-cart is triggered
     */
    addAllToCart() {
      let messages = []

      for (let item of this.wishlistItems) {
        this.$http.post(`${this.apiPath}/wishlist_items/add_to_cart`, { product_id: item.product_id, options: item.options }).then(response => {
          messages.push(response.body.message)
        })
      }

      let $this = this

      var timer = setInterval( () => {
        if (messages.length == $this.wishlistItems.length) {
          miniCart.loadOrder()
          $this.loadWishlistItems()
          $this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-added-all-to-cart', message: messages[0] })

          clearInterval(timer)
        }
      }, 30)
    },

    deleteAllWishlistItems() {
      this.$http.post(`${this.apiPath}/wishlist_items/destroy_all`).then(response => {
        this.loadWishlistItems()
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-deleted-all', message: response.body.message })
      })
    }
  }
}