import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import miniWishlist from '../apps/mini_wishlist'
import { store } from '../lib/store'
import Coupon from '../components/coupon.vue'
import LineItem from '../components/line_item.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})


export default new Vue({
  el: '#gy-mini-cart',
  store,
  components: {
    Coupon,
    LineItem
  },
  computed: {
    ...mapState([
      'order',
      'recentlyAdded'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    customAttributes() {
      return (this.$el.attributes['custom-attributes'] || {}).value
    },
    versions() {
      return (this.$el.attributes.versions || {}).value
    },
    stringifiedVersions() {
      if (this.versions) {
        return JSON.stringify(JSON.parse(this.versions))
      }

      return undefined
    },
    cartEmpty() {
      return this.order.total_quantity == undefined || this.order.total_quantity < 1
    },
    cartTotalQuantity() {
      return this.order.total_quantity
    }
  },
  mounted() {
    if (this.$el.id && this.$el.id.length > 0) {
      this.loadOrder()
    }
  },
  methods: {
    /*
     * Loads the order from the API. Its called once when app is mounted.
     */
    loadOrder() {
      this.$http.get(`${this.apiPath}/order`, { params: { versions: this.stringifiedVersions, custom_attributes: this.customAttributes } }).then(response => {
        this.$store.state.order  = response.body.order
        this.$store.state.coupon = response.body.coupon
        this.$store.commit('setUser', response.body.user)

        if (this.signedIn() && miniWishlist.$el.id) {
          miniWishlist.loadWishlistItems()
        }

        this.$store.dispatch('triggerEvent', 'gy::order-loaded')
      })
    },
    signedIn() {
      return this.$store.state.user && this.$store.state.user.id > 0
    }
  }
})
