<template>
  <div>
    <input
      v-if="address"
      v-model="address.vat_number"
      type="text"
      :placeholder="placeholder"
      @keyup="initValidation"
    >
    <input
      v-if="!address"
      :id="inputId"
      v-model="vatNumber"
      type="text"
      :name="inputName"
      :placeholder="placeholder"
      @keyup="initValidation"
    >
    <span
      v-if="!validNumber"
      class="error-class"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<style>
  .error-class {
    color: red;
  }
</style>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import NiceI18n    from '../lib/nice_i18n'

export default Vue.component('vat-number-field', {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    address: {
      default: () => {
        return undefined
      },
      type: Object
    },
    inputName: {
      type: String,
      default: undefined
    },
    inputId: {
      default: undefined
    }
  },
  data() {
    return {
      vatNumber: '',
      validNumber: true,
      errorMessage: NiceI18n.t("activerecord.errors.models.address.attributes.vat_number.invalid"),
      timeout: null
    }
  },
  computed: {
    ...mapState([
      "order"
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    countryId() {
      if (this.address && this.address.country_id) {
        return this.address.country_id
      }
      else {
        return this.$store.state["billing_address"].country.id
      }
    }
  },
  methods: {
    initValidation() {
      clearTimeout(this.timeout)

      this.timeout = setTimeout( () => {
        if (this.address) {
          this.vatNumber = this.address.vat_number
        }
        this.runValidation()
      }, 1000)
    },

    runValidation() {
      if (this.vatNumber && this.vatNumber.length >= 8) {
        this.errorMessage = NiceI18n.t("activerecord.errors.models.address.attributes.vat_number.invalid")

        this.$http.post(`${this.apiPath}/vat_number_validations/validate`, { vat_number: this.vatNumber, country_id: this.countryId }).then(response => {
          this.validNumber = response.body.error ? true : response.body.valid
        })
      }
      else {
        this.validNumber = false
      }

      this.hideExistingErrorField()
    },

    hideExistingErrorField() {
      if (this.$parent.$refs.errorField) {
        this.$parent.$refs.errorField.style.display = 'none'
      }
    }
  }
})
</script>