<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default Vue.component('option-variants-table', {
  props: {
    chosenOptions: {
      default: () => [],
      type: Array
    },
    options: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      totalAmount: 0.0,
      totalSavings: 0.0
    }
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    activeOption() {
      return (this.options)[0]
    },
    optionVariants() {
      let optionVariants = (this.activeOption || {}).option_variants

      if (optionVariants) {
        optionVariants.sort((a, b) => {
          return a.title - b.title
        })
      }

      return optionVariants
    }
  },
  methods: {
    showOptionVariantsTable() {
      return (this.$parent.step > this.chosenOptions.length)
    },

    updateTotal() {
      let totalDiscounted = 0.0
      let total = 0.0

      for (let component of this.$refs.optionVariantRow) {
        if (component.quantity > 0) {
          totalDiscounted += component.rawDiscountedPrice * parseInt(component.quantity)
          total += component.rawPrice * parseInt(component.quantity)
        }
      }

      this.totalAmount = totalDiscounted
      this.totalSavings = total - totalDiscounted

      this.$parent.optionVariantsTableFilled = (total > 0)
    }
  }
})
</script>