import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'
import SiteSelector from '../components/site_selector.vue'

$('.gy-app').each(function() {
  let id = $(this).attr('id')
  new Vue({
    el: this,
    store,
    components: {
      SiteSelector
    },
    computed: {
      ...mapState([
        'order',
        'recentlyAdded'
      ]),
      cartTotalQuantity() {
        return this.order.total_quantity
      },
      signedIn() {
        return this.$store.state.user && this.$store.state.user.id > 0
      }
    }
  })
})