export default class NiceI18n {

  static t(el) {
    if (typeof I18n == "undefined") {
      return ""
    }
    else {
      I18n.locale = window.locale
      return I18n.t(el)
    }
  }

}
