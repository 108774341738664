import Vue      from 'vue'
import VModal   from 'vue-js-modal'
import VueNoty  from 'vuejs-noty'

Vue.use(VModal, { dialog: true, dynamic: true })
Vue.use(VueNoty)

export default {
  methods: {
    showModal(message, buttons) {
      this.$modal.show('dialog', {
        text: message,
        buttons: buttons
      })
    }
  }
}
