<template>
  <div>
    <h4>{{ store.title }}</h4>
    <p><strong>ΔΙΕΥΘΥΝΣΗ:</strong> {{ store.address }}</p>
    <p v-show="store.telephone">
      <strong>ΤΗΛΕΦΩΝΟ:</strong> {{ store.telephone }}
    </p>
    <p v-show="store.email">
      <strong>EMAIL:</strong> <a :href="'mailto:' + store.email">
        {{ store.email }}
      </a>
    </p>
    <p v-show="store.website">
      <strong>WEBSITE:</strong> <a
        :href="store.website"
        target="_blank"
      >
        {{ store.website }}
      </a>
    </p>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

export default Vue.component('map-info-content', {
  props: {
    store: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  }
})
</script>