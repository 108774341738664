<template>
  <GmapMap
    ref="map"
    :center="internalCenter"
    :zoom="internalZoom"
    :options="options"
    class="map"
    @bounds_changed="refreshVisibleInMapStores"
  >
    <GmapInfoWindow
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen=false"
    >
      <span v-html="infoContent" />
    </GmapInfoWindow>

    <GmapCluster>
      <GmapMarker
        v-for="(m, index) in markers"
        ref="markers"
        :key="m.id"
        :position="m.position"
        :clickable="true"
        :draggable="false"
        @click="updateCenter(m);toggleInfoWindow(m, index)"
      />
    </GmapCluster>
  </GmapMap>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { EventBus } from '../../lib/event_bus.js'

import * as VueGoogleMaps from 'vue2-google-maps'

if (typeof google === 'undefined') {
  Vue.use(VueGoogleMaps, {
    load: {
      key: window.google_api_key,
      libraries: 'places' // This is required if you use the Autocomplete plugin
      // OR: libraries: 'places,drawing'
      // OR: libraries: 'places,drawing,visualization'
      // (as you require)
    }
  })
}

export default Vue.component('stores-map', {
  props: {
    stores: {
      default: () => [],
      type: Array
    },
    selectedFilters: {
      default: () => {
        return {}
      },
      type: Object
    },
    zoom: {
      default: 4,
      type: Number
    },
    center: {
      default: () => {
        return { lat: 10.0, lng: 10.0 }
      },
      type: Object
    }
  },
  data () {
    VueGoogleMaps.loaded.then( () => {
      this.options = {
        streetViewControl: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        }
      }
    })

    return {
      doNotRefreshStores: false,
      visibleStoreIds: [],
      options: {},
      infoContent: '',
      internalZoom: this.zoom,
      internalCenter: this.center,
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -38
        }
      },
      tmt: null
    }
  },
  computed: {
    ...mapState([
      'order'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    markers() {
      var markers = []
      for (let store of this.stores) {
        markers.push({ id: store.id, position: { lat: Number(store.latitude), lng: Number(store.longitude) }, store: store })
      }

      return markers
    }
  },
  created() {
    EventBus.$on('store-selected', this.centerMapOnStore)
  },
  methods: {
    centerMapOnStore(store) {
      this.doNotRefreshStores = true
      this.internalCenter     = { lat: Number(store.latitude), lng: Number(store.longitude) }
      this.internalZoom       = store.zoomlevel.length > 0 ? store.zoomlevel : 17

      for (let marker of this.markers) {
        if (store.id == marker.id) {
          this.toggleInfoWindow(marker, 0)
          break
        }
      }
    },

    updateCenter(marker_or_place) {
      this.doNotRefreshStores = false

      if (marker_or_place.position) {
        // We have a marker. Get karfoten position.
        this.internalCenter = marker_or_place.position
      } else {
        // We have a place, get lat & lng from Google.
        this.internalCenter = {
          lat: marker_or_place.geometry.location.lat(),
          lng: marker_or_place.geometry.location.lng()
        }

        this.$refs.map.fitBounds(marker_or_place.geometry.viewport)
      }
    },

    toggleInfoWindow: function(marker, idx) {
      this.$parent.selectedStore = marker.store
      this.infoWindowPos         = marker.position

      this.$nextTick(() => {
        this.infoContent = this.$parent.$refs.mapInfoContent.$el.innerHTML
      })

      // Check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen
      }
      // If different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.currentMidx = idx
      }
    },

    refreshVisibleInMapStores(bounds) {
      if (bounds == undefined) {
        bounds = this.$refs.map.$mapObject.getBounds()
      }

      clearTimeout(this.tmt)

      if (this.doNotRefreshStores)
        return

      this.tmt = setTimeout(() => {
        var visibleInMapStores = []

        for (let store of this.stores) {
          // Check if we are inside bounds of map
          if (bounds.contains(new google.maps.LatLng({ lat: Number(store.latitude), lng: Number(store.longitude) })))
            visibleInMapStores.push(store)
        }

        console.log("Visible MAP stores refreshed")
        EventBus.$emit('visible-in-map-stores-refreshed', visibleInMapStores)
      }, 50)
    }
  }
})
</script>