<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import miniCart from '../apps/mini_cart'

export default Vue.component('coupon', {
  props: {
    couponType: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      code: null,
      thirdPartyCouponLoading: false,
      errors: {
        coupon: null
      }
    }
  },
  computed: {
    ...mapState([
      'order',
      'coupon'
    ]),
    ...mapGetters([
      'apiPath'
    ]),
    couponActivated() {
      return this.coupon && this.coupon.code && this.coupon.code.length > 0
    }
  },
  methods: {
    /*
     * It activates the coupon.
     * @event - gy::coupon-activated event is triggered when activated
     * @event - gy::coupon-invalid event is triggered when is invalid
     */
    activateCoupon() {
      if (this.couponType) this.toggleThirdPartyCouponLoader()

      return this.$http.post(`${this.apiPath}/coupons/activate`, { code: this.code, coupon_type: this.couponType }).then(response => {
        miniCart.loadOrder()
        this.errors.coupon = null
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-activated', message: response.body.message })

        if (this.couponType) this.toggleThirdPartyCouponLoader()
      }, response => {
        this.errors.coupon = null

        setTimeout( () =>
          this.errors.coupon = response.body.message
        , 80)

        if (this.couponType) this.toggleThirdPartyCouponLoader()
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-invalid', message: response.body.message })
      })
    },

    /*
     * It deactivates the coupon.
     * @event - gy::coupon-deactivated event is triggered when activated
     */
    deactivateCoupon() {
      return this.$http.post(`${this.apiPath}/coupons/deactivate`).then(response => {
        miniCart.loadOrder()
        this.$store.dispatch('triggerEvent', { type: 'gy::coupon-deactivated', message: response.body.message })
      })
    },

    /*
     * It toggles third party coupon loader.
     */
    toggleThirdPartyCouponLoader() {
      this.thirdPartyCouponLoading = !this.thirdPartyCouponLoading
    }
  }
})
</script>