import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'
import Coupon  from '../components/coupon.vue'
import ConsentsContainer  from '../components/moad/consents_container.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

$('.gy-cart').each(function() {
  new Vue({
    el: this,
    store,
    components: {
      Coupon,
      ConsentsContainer
    },
    computed: {
      ...mapState([
        'user',
        'order',
        'coupon'
      ]),
      versions() {
        return (this.$el.attributes.versions || {}).value
      },
      stringifiedVersions() {
        if (this.versions) {
          return JSON.stringify(JSON.parse(this.versions))
        }

        return undefined
      },
      cartEmpty() {
        return this.order.total_quantity == undefined || this.order.total_quantity < 1
      },
      checkoutDisabled() {
        if (this.order.line_items == undefined) return true

        for (let line_item of this.order.line_items) {
          if (!line_item.fulfils_restrictions) {
            return true
          }
        }
        return false
      }
    },
    methods: {
      checkout(event) {
        if (this.checkoutDisabled) {
          event.preventDefault()
        }
      }
    }
  })
})