import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import miniCart from '../apps/mini_cart'

import wishlistMixin from '../mixins/wishlist_mixin'
import WishlistItem from '../components/wishlist_item.vue'
import Product from '../components/product.vue'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

export default new Vue({
  el: '#gy-wishlist',
  store,
  components: {
    WishlistItem,
    Product
  },
  mixins: [wishlistMixin],
  mounted() {
    if (this.$el.id && this.$el.id.length > 0) {
      this.loadWishlistItems(false)
    }
  }
})
