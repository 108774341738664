// Apps
import App from '../../apps/moad/app'
import Utils from '../../lib/utils'
import Vue from 'vue'
import VueResource from 'vue-resource'
import queryString from 'query-string'

// Components
import ConsentsContainer from '../../components/moad/consents_container.vue'
import CookieModal from '../../components/moad/cookie_modal.vue'

Vue.use(VueResource)
Vue.url.options.root = "https://gdpr.gy.digital/api/v1"
//Vue.url.options.root = "http://localhost:4000/api/v1"
export default class Base {
  constructor() {
    this.screens = []

    if (window.gy_moad != undefined) {
      this.loadConsents()
    }
    else {
      window.triggerGYAnalytics()
    }
  }

  setDomainAndToken() {
    this.domain = window.gy_moad.domain
    this.token  = window.gy_moad.token

    if (window.gy_moad.locale != undefined) {
      this.locale = window.gy_moad.locale
    } else {
      this.locale = "el"
    }
  }

  loadConsents() {
    let resource = new Vue
    this.setDomainAndToken()

    resource.$http.get("consents", {
      params: {
        domain: this.domain,
        locale: window.locale || this.locale
      },
      headers: {
        "X-Authorization-Token": this.token
      }
    }).then(response => {
      this.screens                = response.body.screens
      this.test                   = response.body.test
      this.cookieConsent          = response.body.cookie_consent
      this.requiredConsentMessage = response.body.required_consent_message

      // If the site has preconsented_policy enabled, then set preconsented_policy to TRUE.
      // Also, if the Cookie Pop-up exists, if it is in Test Mode, the LIVE site will be having an issue where the scripts won't be
      // getting executed due to the fact that the user has never consented, since they never saw the cookie pop-up to do so.
      // Therefore, we are setting the preconsented_policy as TRUE,
      // if the cookie pop-up is in Test Mode and the enable_moad parameter is missing from the URL.
      GYCookie.preconsented_policy = this.cookieConsent.preconsented_policy || (this.cookieConsent && this.cookieConsent.test && !this.enableMoadInParams())

      // Plain text script tags have to be converted though first to javascript/text
      if (GYCookie.preconsented_policy) {
        GYCookie.reinitialized = true
        GYCookie.initializeScripts()
      }

      window.triggerGYAnalytics()

      Utils.defer("jQuery", () => {
        this.render()
      })
    }).catch(() => {
      window.triggerGYAnalytics()
    })
  }

  enableMoadInParams() {
    // Check for param enable_moad to allow execution or not
    var params = queryString.parse(queryString.extract(window.location.href))

    if (params["enable_moad"] != undefined) {
      return true
    } else {
      return false
    }
  }

  testMode() {
    // Allow API to set TEST mode
    if (!this.test)
      return false

    return !this.enableMoadInParams()
  }

  render() {
    if (this.testMode())
      return false

    let index = 0
    var $this = this

    // Initialize apps for each screen
    for (let screen of this.screens) {
      let $selector = $(screen.selector)

      if ($selector.length > 0) {
        $selector.each(function() {
          $(this).attr('id', `gy-gdpr-${screen.id}`)
          $(this).addClass(`gy-gdpr-container-${index}`)

          if ($this.enableMoadInParams()) {
            $(this).addClass('gy-gdpr-testing-enabled')
            $(this).prepend($(`<div class="gdpr-testing-label">ID ${screen.id}</div>"`))
          }

          new ConsentsContainer({
            el: $(this).get(0),
            data: {
              screen: screen,
              test: $this.test,
              requiredConsentMessage: $this.requiredConsentMessage
            }
          })

          index++
        })
      } else {
        if (this.test)
          Utils.logNotice(`(SCREEN ID: ${screen.id}) Element with selector ${screen.selector} NOT found!`, "MoaD")
      }
    }

    this.mountCookieModal()
  }

  cookieTestMode() {
    // Allow API to set TEST mode
    if (!this.cookieConsent.test)
      return false

    return !this.enableMoadInParams()
  }

  mountCookieModal() {
    // Return if cookieConsent if empty / API didn't return any cookie consent
    if (Object.keys(this.cookieConsent).length === 0 && this.cookieConsent.constructor === Object)
      return

    if (this.cookieTestMode())
      return false

    let template = $("<cookie-modal />")
    $('body').prepend(template.get(0))

    new CookieModal({
      el: template.get(0),
      data: {
        cookieConsent: this.cookieConsent
      }
    })
  }
}