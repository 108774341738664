import Vue from 'vue'
import Vuex from 'vuex'
import VueResource from 'vue-resource'
import VuexPersist from 'vuex-persist'

// Lib
import Utils from '../lib/utils'

Vue.use(Vuex)

export const vuexLocalStorage = new VuexPersist({
  storage: window.localStorage,
  reducer: state => ({
    activeGiftListId: state.activeGiftListId
  })
})

export const store = new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    order: {},
    displayGiftListBar: true,
    coupon: {},
    user: {},
    wishlistItems: [],
    recentlyAdded: [],
    locale: window.locale || 'el',
    site_code: window.site_code || null,
    history: [],
    lineItemImageVersions: {},
    wishlistItemImageVersions: {},
    giftLists: [],
    giftListsLoaded: false,
    activeGiftListId: null,
    addressCreatedFromGiftList: false,
    billing_address: {},
    shipping_address: {}
  },
  getters: {
    apiPath: (state) => {
      return `/${state.site_code}/${state.locale}/api/v2`
    },
    siteLocalePath: (state) => {
      return `/${state.site_code}/${state.locale}`
    },
    activeGiftList: (state) => {
      return (Utils.detect(state.giftLists, state.activeGiftListId) || {})
    }
  },
  mutations: {
    setGiftLists(state, payload) {
      state.giftLists = payload
    },
    setActiveGiftListId(state, payload) {
      state.activeGiftListId = payload
    },
    setAddress(state, payload) {
      // Weird syntax
      // For reference see: https://github.com/tc39/proposal-object-rest-spread & http://stackoverflow.com/questions/19837916/creating-object-with-dynamic-keys
      state[payload.type] = { ...state[payload.type], [payload.key]: payload.value }
    },
    setUser(state, payload) {
      state.user = payload
    }
  },
  actions: {
    triggerEvent(context, object) {
      let message, type

      if (object) {

        if (typeof(object) == "object") {
          type    = object.type
          message = object.message
        }
        else {
          type    = object
          message = ''
        }

        let event = new CustomEvent(type, {
          detail: {
            message: message,
            event: object.event,
            item: object.item,
            optionVariantId: object.optionVariantId,
            time: new Date()
          },
          bubbles: true,
          cancelable: true
        })

        Utils.defer("gyEvents", () => {
          setTimeout(function() {
            document.dispatchEvent(event)
          }, 40)
        })
      }
    },
    setHistory(context, options) {
      if (options.component) {
        context.state.history.push(options)
      }
    }
  }
})