import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

// Polyfill for creating CustomEvents on IE9/10/11
import customEventPolyfill from 'custom-event-polyfill'

// Classes
import Catalog from './classes/catalog'
import Product from './classes/product'
import MoadCookie from './classes/moad/cookie'
import ConsentChecker from './classes/moad/consent_checker'

// Lib
import Utils    from './lib/utils'
import NiceI18n from './lib/nice_i18n'

// Filters
import './filters/uppercase.js'
import './filters/capitalize.js'
import './filters/currency.js'
import './filters/round_percentage.js'

// Components
import LineItem             from './components/line_item.vue'
import Coupon               from './components/coupon.vue'
import Bundle               from './components/bundle.vue'
import WishlistItem         from './components/wishlist_item.vue'
import ProductComponent     from './components/product.vue'
import Stores               from './components/stores.vue'
import OptionVariantsTable  from './components/option_variants_table.vue'
import OptionVariantRow     from './components/option_variant_row.vue'
import ApivitaBadge         from './components/apivita_badge.vue'
import GiftListItem         from './components/gift_lists/GiftListItem.vue'
import GiftListSelectActive from './components/gift_lists/GiftListSelectActive.vue'
import GiftListShare        from './components/gift_lists/GiftListShare.vue'
import AddressForm          from './components/address_form.vue'
import AddressItem          from './components/address_item.vue'
import Addresses            from './components/addresses.vue'
import SiteSelector         from './components/site_selector.vue'
import SiteSelectorModal    from './components/site_selector_modal.vue'
import GiftList             from './components/gift_lists/GiftList.vue'
import GiftLists            from './components/gift_lists/GiftLists.vue'
import GiftListForm         from './components/gift_lists/GiftListForm.vue'

// Apps
import miniCart             from './apps/mini_cart'
import cart                 from './apps/cart'
import app                  from './apps/app'
import miniWishlist         from './apps/mini_wishlist'
import wishlist             from './apps/wishlist'
import giftList             from './apps/gift_list'
import loginForm            from './apps/login_form'
import registerForm         from './apps/register_form'
import checkout             from './apps/checkout'
import bundles              from './apps/bundles'
import address              from './apps/address'

// Catalog
export const catalogInstance = new Catalog

// Products
export const productInstance = new Product

/**
 * Triggers GY Analytics
 *
*/
window.triggerGYAnalytics = () => {
  // Register the event
  let event = new CustomEvent('gy::nicevueLoaded', {
    detail: {
      message: "Nicevue is ready for GY Analytics",
      time: new Date()
    },
    bubbles: true,
    cancelable: true
  })

  // Trigger the event
  document.dispatchEvent(event)

  // Set on window that nicevue has been loaded
  window.gy_data = window.gy_data || {}
  window.gy_data["nicevue_loaded"] = true
}

// MoaD
import MoadBase from './classes/moad/base'
export const moadBaseInstance = new MoadBase

export const GYCookie = new MoadCookie

window.consentChecker = new ConsentChecker

/**
 * Add our event listeners to document.
 *
*/
const bindGYEvents = () => {
  var gyEvents = [
    'gy::options-start-loading',
    'gy::options-loaded',
    'gy::photos-changed',
    'gy::option-variant-changed',
    'gy::added-to-cart',
    'gy::cannot-add-to-cart',
    'gy::user-needs-login',
    'gy::wishlist-loaded',
    'gy::wishlist-item-deleted',
    'gy::wishlist-deleted-all',
    'gy::wishlist-added-to-cart',
    'gy::wishlist-added-all-to-cart',
    'gy::added-to-wishlist',
    'gy::already-exists-in-wishlist',
    'gy::giftlist-created',
    'gy::giftlist-updated',
    'gy::giftlist-product-added',
    'gy::giftlist-product-not-added',
    'gy::giftlists-loaded',
    'gy::giftlist-form-populated',
    'gy::giftlist-deleted',
    'gy::giftlist-item-added',
    'gy::giftlist-item-removed',
    'gy::giftlist-set-completed',
    'gy::giftlist-share',
    'gy::giftlist-set-completed-error',
    'gy::giftlist-share-link-success',
    'gy::giftlist-share-link-error',
    'gy::user-logged-in',
    'gy::user-not-authorized',
    'gy::user-registered',
    'gy::user-not-registered',
    'gy::app-mounted',
    'gy::catalog-visit-started',
    'gy::catalog-visit-done',
    'gy::catalog-paginate',
    'gy::catalog-last-page',
    'gy::coupon-activated',
    'gy::coupon-invalid',
    'gy::coupon-deactivated',
    'gy::order-loaded',
    'gy::line-item-deleted',
    'gy::line-item-quantity-updated',
    'gy::payment-methods-loaded',
    'gy::shipping-methods-loaded',
    'gy::countries-loaded',
    'gy::regions-loaded',
    'gy::vat-offices-loaded',
    'gy::bundles-loaded',
    'gy::bundle-added-to-cart',
    'gy::bundle-cannot-add-to-cart',
    'gy::store-filter-selected',
    'gy::address-form-populated',
    'gy::address-deleted',
    'gy::address-error-deleted',
    'gy::address-updated',
    'gy::address-created',
    'gy::checkout-address-populated'
  ]

  for (var i = 0; i < gyEvents.length; i++) {
    document.addEventListener(gyEvents[i], function(event) {
      var name = event.type.replace('gy::', '').replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() })

      if (window.gyEvents && window.gyEvents[name]) {
        window.gyEvents[name](event)
        console.log(`[GY] Event \`${name}\` was fired!`)
      }
      else {
        console.log(`[GY] EVENT NOT FOUND => \`${name}\`. Please define it in the website!`)
      }
    })
  }
}

Utils.defer("gyEvents", () => {
  bindGYEvents()
})
