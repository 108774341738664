<script>
import Vue from 'vue'

export default Vue.component('apivita-badge', {
  props: {
    siteName: {
      type: String,
      default: ""
    },
    badgeWrapperId: {
      type: String,
      default: ""
    }
  },
  mounted() {
    let apivitaBadgeElement = document.createElement('script');    
    apivitaBadgeElement.setAttribute('src', 'https://badge.apivita.com/dist/index.js');
    apivitaBadgeElement.setAttribute('data-name', this.siteName);
    document.getElementById(this.badgeWrapperId).appendChild(apivitaBadgeElement);
  }
})
</script>