import Vue from 'vue'
import $ from 'jquery'

import ProductComponent from '../components/product.vue'

export default class Product {
  constructor() {
    this.apps = {}
    this.initializeAll()
  }

  clearApps() {
    for (let key of Object.keys(this.apps)) {
      this.apps[key].$destroy()
    }

    this.apps = {}
  }

  initializeAll() {
    let $this = this

    $('.gy-product').each(function(index, el) {
      var id = "gy-product-" + Date.now() + "-" + index
      $(el).attr('id', id)

      if ($this.apps[id] == undefined) {
        $this.apps[id] = $this.initialize(id)
      }
    })
  }

  /*
   * Initialize Vue app
   */
  initialize(id) {
    return new Vue({
      el: `#${id}`,
      components: {
        ProductComponent
      },
      data() {
        return {
          versions: {},
          productId: null,
          load: false
        }
      },
      computed: {
        alreadyAddedToCart() {
          if (this.$children[0]) {
            return this.$children[0].alreadyAddedToCart()
          }
          else {
            return false
          }
        }
      },
      beforeMount() {
        if (this.$el.attributes.productId) {
          this.productId = this.$el.attributes.productId.value
        }

        if (this.$el.attributes.versions) {
          this.versions = this.$el.attributes.versions.value
        }

        if (this.$el.attributes.load) {
          this.load = true
        }
      }
    })
  }
}
