<template>
  <div style="display: inline;">
    <MultiSelect
      :options="vat_offices"
      :value="vat_office"
      label="title"
      track-by="id"
      :placeholder="placeholder"
      :selected-label="selectedLabel"
      :select-label="selectLabel"
      :deselect-label="deselectLabel"
      :close-on-select="true"
      :clear-on-select="true"
      @select="select"
      @remove="remove"
    />
    <input
      v-model="vat_office.id"
      type="hidden"
      :name="inputName"
    >
    <span
      v-if="$v.vat_office.id.$error"
      class="error"
    >
      {{ error_message }}
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import MultiSelect  from 'vue-multiselect'
import Utils        from '../lib/utils'
import NiceI18n     from '../lib/nice_i18n'

import { validationMixin }    from 'vuelidate'
import { required, numeric }  from 'vuelidate/lib/validators'

export default Vue.component('vat-office-select', {
  components: {
    MultiSelect
  },
  mixins: [validationMixin],
  props: {
    placeholder: {
      type: String,
      default: "Παρακαλώ επιλέξτε Δ.Ο.Υ"
    },
    selectedLabel: {
      type: String,
      default: ""
    },
    selectLabel: {
      type: String,
      default: ""
    },
    deselectLabel: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      error_message: NiceI18n.t("activerecord.errors.models.address.attributes.vat_office_id.blank")
    }
  },
  computed: {
    ...mapState([
      "order"
    ]),
    vat_office() {
      if (this.$store.state["billing_address"].vat_office) {
        return this.$store.state["billing_address"].vat_office
      } else {
        return {}
      }
    },
    vat_offices() {
      if (this.$store.state["billing_address"].vat_offices) {
        return this.$store.state["billing_address"].vat_offices
      }
      else {
        return []
      }
    },
    inputName() {
      return `order[billing_address_attributes][vat_office_id]`
    }
  },
  validations: {
    vat_office: {
      id: {
        // required,
        numeric
      }
    }
  },
  methods: {
    select(option) {
      this.$store.commit("setAddress", { type: "billing_address", key: "vat_office", value: option })
      this.$v.vat_office.id.$touch()
    },
    remove() {
      this.$store.commit('setAddress', { type: "billing_address", key: "vat_office", value: null })
      this.$v.vat_office.id.$touch()
    }
  }
})
</script>
