<script>
import Vue from 'vue'
import * as Cookies from 'js-cookie'
import Utils from '../lib/utils'
import queryString from 'query-string'
import NiceI18n from '../lib/nice_i18n'

export default Vue.component('site-selector-modal', {
  props: {
    cookieName: {
      type: String,
      default: undefined
    },
    scenario: {
      type: String,
      default: undefined
    },
    sites: {
      default: () => undefined,
      type: Array
    },
    preselectedSite: {
      default: () => {
        return undefined
      },
      type: Object
    },
    visitorCountry: {
      default: undefined
    }
  },
  data() {
    return {
      site: {},
      locale: {}
    }
  },
  computed: {
    sameCountry() {
      return this.scenario == '1'
    },
    supportedCountry() {
      return this.scenario == '2'
    },
    unsupportedCountry() {
      return this.scenario == '3'
    },
    isSiteSelected() {
      return !Utils.checkForEmptyObject(this.site)
    },
    selectedCountry() {
      return this.isSiteSelected ? this.site.title : NiceI18n.t('shared.site_selector_popup.choose_country')
    },
    isLocaleSelected() {
      return !Utils.checkForEmptyObject(this.locale)
    },
    selectedLanguage() {
      return this.isLocaleSelected ? Object.values(this.locale)[0] : NiceI18n.t('shared.site_selector_popup.choose_language')
    },
    locales() {
      return this.isSiteSelected ? this.site.locales : []
    },
    localeCode() {
      return this.isLocaleSelected ? Object.keys(this.locale)[0] : null
    },
    submitEnabled() {
      return this.isSiteSelected && this.isLocaleSelected
    },
    firstSite() {
      return this.sites ? this.sites[0] : {}
    },
    currentSite() {
      return this.sites.filter((site) => site.current)[0]
    }
  },
  mounted() {
    this.preselect()
  },
  methods: {
    preselect() {
      if (!Utils.checkForEmptyObject(this.preselectedSite)) {
        this.site = this.preselectedSite
        this.locale = this.locales[0]
      }
    },
    setSiteAndLocale(event) {
      this.site = this.sites.filter((site) =>
        site.url == event.target.parentNode.getAttribute('value')
      )[0]

      this.locale = this.locales[0]

      this.toggleList(event)
    },
    setLocale(event) {
      this.locale = this.locales.filter((locale) =>
        Object.keys(locale)[0] == event.target.parentNode.getAttribute('value')
      )[0]

      this.toggleList(event)
    },
    submit() {
      if (!this.submitEnabled) return

      let params = queryString.parse(queryString.extract(window.location.href))
      params[this.cookieName] = true

      let b2b = this.site.b2b ? '/b2b' : ''
      let url = this.site.url + '/' + this.site.code + '/' + this.localeCode + b2b + '/?' + queryString.stringify(params)

      window.location.href = url
    },
    redirectToPreselectedSite() {
      this.site = this.preselectedSite
      this.locale = this.locales[0]
      this.submit()
    },
    stayOnSelectedSite() {
      Cookies.set(this.cookieName, '1')
      this.$emit('close')
    },
    toggleLocalesList(event) {
      if (this.isSiteSelected)
        this.toggleList(event)
    },
    toggleList(event) {
      let element = Utils.closest(event.target, (el) => {
        return !!el && el !== document && Utils.hasClass(el, 'select-block')
      })

      if (element)
        element.classList.toggle('open')
    }
  },
  template: '#site-selector-modal-template'
})
</script>
