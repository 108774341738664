import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'
import $ from 'jquery'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

// Add $eventHub to Vue as prototype
Vue.prototype.$eventbus = new Vue();

$('.gy-giftlist').each(function() {
  new Vue({
    el: this,
    store,
    computed: {
      ...mapGetters([
        'apiPath'
      ])
    }
  })
})
