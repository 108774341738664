<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'

import Utils from '../lib/utils'

import wishlist from '../apps/wishlist'
import miniCart from '../apps/mini_cart'

import NiceI18n from '../lib/nice_i18n'
import niceModal from '../mixins/nice_modal'

import queryString from 'query-string'

export default Vue.component('product', {
  mixins: [niceModal],
  props: {
    product: {
      default: () => {
        return undefined
      },
      type: Object
    },
    productId: {
      type: String,
      default: ""
    },
    versions: {
      type: String,
      default: ""
    },
    load: {
      type: String,
      default: undefined
    },
    colorId: {
      type: String,
      default: undefined
    },
    wizardlessOptions: {
      default: () => [],
      type: Array
    },
    showSkuOnlyAttributeIfSkus: {
      type: String,
      default: "false"
    },
    giftListId: {
      type: Number,
      default: undefined
    }
  },
  store,
  data() {
    return {
      options: [],
      custom_options: [],
      skus: [],
      stocks: [],
      images: [],
      errors: {},
      productObject: {},
      sku: {},
      selected: {},
      addedToCart: false,
      clickedWhenDisabled: false,
      canBuy: true,
      optionVariantsTableFilled: false,
      quantity: 1,
      maxQuantity: 1,
      currentPhotos: [],
      hasAvailableSizes: false,
      loadOptionsCalled: false,
      step: 1
    }
  },
  computed: {
    quantities() {
      // Creates an range array from 1...this.maxQuantity
      return Array(this.maxQuantity).fill().map((_, i) => i + 1)
    },
    alreadyAddedToCart() {
      return this.quantityAddedToCart > 0
    },
    quantityAddedToCart() {
      let quantity = 0

      for (let line_item of this.productLineItems) {
        quantity += line_item.quantity
      }

      return quantity
    },
    totalPriceAddedToCart() {
      if (this.productLineItems.length > 0) {
        return this.productLineItems[0].total_discounted_price
      } else {
        return null
      }
    },
    formattedStocks() {
      let array = []
      for (let key of Object.keys(this.stocks)) {
        let integer_key = key.split(',').map(x => +x)
        array.push({ key: integer_key, quantity: this.stocks[key] })
      }
      return array
    },
    productLineItems() {
      let lineItems = []

      if (!this.order || !this.order.line_items || this.order.line_items.length < 1) {
        return []
      }

      for (let lineItem of this.order.line_items) {
        if (lineItem.product_id == parseInt(this.computedProductId)) {
          lineItems.push(lineItem)
        }
      }

      return lineItems
    },
    wizardDisplay() {
      return (this.wizardlessOptions && this.wizardlessOptions.length > 0)
    },
    canAddToCart() {
      let can = this.canBuy

      if (this.wizardDisplay) {
        can = this.optionVariantsTableFilled
      } else {
        for (let option of this.options) {
          if (!this.selected[option.id]) {
            can = false
            break
          }
        }
      }

      return can
    },
    computedProductId() {
      if (this.product != undefined) {
        return this.product.id
      }
      else {
        return this.productId
      }
    },
    colorOption() {
      for (let option of this.options) {
        if (option.is_color) {
          return option
        }
      }
      return null
    },
    colors() {
      let colorOption = this.colorOption
      return colorOption ? colorOption.option_variants : []
    },
    sizeOption() {
      for (let option of this.options) {
        if (option.is_size) {
          return option
        }
      }
      return null
    },
    sizes() {
      if (this.colorOption) {
        var selectedOptionVariant = this.detect(this.colorOption.option_variants, this.colorId)
        this.setSelected(selectedOptionVariant)
      }

      let sizeOption = this.sizeOption
      return sizeOption ? sizeOption.option_variants : []
    },
    availableSizes() {
      var sizes = this.sizes.map(option => {
        if (!option.disabled) {
          return option
        } else {
          return false
        }
      }).filter(Boolean)

      return sizes
    },
    selectedKey() {
      let selectedKey = new Array(this.options.length).fill(null)

      // Create key to search in stocks - for example [5, 4] option variant ids
      for (let key of Object.keys(this.selected)) {
        let value = this.selected[key]
        if (value && parseInt(value) != NaN && parseInt(value) > 0) {
          let index = this.option_ids.indexOf(parseInt(key))
          selectedKey[index] = parseInt(value)
        }
      }

      return selectedKey
    },
    availability() {
      return this.fetchFromSkuOrProduct("availability")
    },
    availabilityTitle() {
      return this.availability && this.availability.name
    },
    offered() {
      return this.fetchFromSkuOrProduct("offered")
    },
    price() {
      return this.fetchFromSkuOrProduct("price")
    },
    discountedPrice() {
      return this.fetchFromSkuOrProduct("discounted_price")
    },
    discountPercentage() {
      return this.fetchFromSkuOrProduct("discount_percentage")
    },
    barcode() {
      return this.fetchFromSkuOrProduct("barcode")
    },
    savings() {
      return this.fetchFromSkuOrProduct("savings")
    },
    wizardlessSplittedOptions() {
      return this.wizardlessOptions.split(",")
    },
    wizardOptions() {
      var options = this.options.map(option => {
        if (this.wizardlessSplittedOptions.indexOf(option.handle) > -1) {
          return false
        } else {
          return option
        }
      }).filter(Boolean)

      return options
    },
    nonWizardOptions() {
      // Difference - See https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
      return this.options.filter(x => !this.wizardOptions.includes(x))
    },
    nonWizardOption() {
      return (this.nonWizardOptions || [])[0]
    },
    nonWizardOptionVariants() {
      let optionVariants = (this.nonWizardOption || {}).option_variants

      if (optionVariants) {
        optionVariants.sort((a, b) => {
          return a.title - b.title
        })
      }

      return optionVariants
    },
    ...mapState([
      'order',
      'history'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    selected: {
      handler: function(val, oldVal) {
        this.refreshAvailabilities()
        this.refreshSkus()
      },
      deep: true
    },
    availableSizes: {
      handler: function(val) {
        if (this.loadOptionsCalled)
          this.hasAvailableSizes = val.length > 0
      },
      deep: true
    },
    quantity: function(val) {
      if (val < 1) {
        this.quantity = 1
      }

      if (val > this.maxQuantity) {
        let $this = this
        setTimeout( () =>
          $this.quantity = $this.maxQuantity
        , 120)
      }
    }
  },
  mounted() {
    if (this.load != undefined)
      this.loadOptions()

    this.skuOnlyAttributeIfSkus = this.showSkuOnlyAttributeIfSkus == "true"
  },
  methods: {
    /*
     * Fetches from API the options, option variants & photos of specific product.
     * This method is called automagically based on `load` prop that is passed in this Vue.js app.
     */
    loadOptions() {
      this.justAddedToCart = false
      this.$store.dispatch('triggerEvent', 'gy::options-start-loading')

      // Get option_variant_id from URL to be used for color preselection
      this.params = queryString.parse(queryString.extract(window.location.href))

      let versions = this.versions ? this.versions : undefined

      return this.$http.get(`${this.apiPath}/products/${this.computedProductId}/options`, { params: { versions: versions } }).then(response => {
        this.unlimited_policy                   = response.body.unlimited_policy || false
        this.options                            = response.body.options
        this.custom_options                     = response.body.custom_options
        this.stocks                             = response.body.stocks
        this.images                             = response.body.images
        this.skus                               = response.body.skus
        this.productObject.availability         = response.body.availability
        this.productObject.prices               = response.body.prices || {}
        this.productObject.offered              = this.productObject.prices.offered
        this.productObject.price                = this.productObject.prices.price
        this.productObject.discounted_price     = this.productObject.prices.discounted_price
        this.productObject.discount_percentage  = this.productObject.prices.discount_percentage
        this.productObject.savings              = this.productObject.prices.savings

        if (response.body.can_buy != undefined)
          this.canBuy  = response.body.can_buy

        this.options.forEach((option) => {
          this.$set(this.selected, String(option.id), null)
          // option.option_variants[0].id
        })

        this.option_ids = this.options.map(option => {
          return option.id
        })

        this.refreshAvailabilities()
        this.refreshSkus()

        // Emit event for successful loading
        this.loadOptionsCalled = true
        this.$store.dispatch('triggerEvent', 'gy::options-loaded')
      })
    },

    /*
     * Loads prices from API for given options of this product.
     * This method is called automagically based on `load` prop that is passed in this Vue.js app.
     */
    loadPrices() {

    },

    /*
     * Sets selected option variant to passed value.
     * This method is used when we use non select elements, for example custom ul/li elements with anchor tags.
     * @param {Object} optionVariant - the option variant to set as the selected one.
     */
    setSelected(optionVariant) {
      if (optionVariant) {
        this.selected[optionVariant.option_id] = optionVariant.id
      }
    },

    /*
     * Returns true if the given optionVariant is actually selected.
     * This method can be used to add a special class for the selected option variants.
     * @param {Object} optionVariant - the option variant to check if it's selected.
     * @return {boolean} - returns true if option variant is selected.
     */
    isSelected(optionVariant) {
      if (optionVariant) {
        return this.selected[optionVariant.option_id] == optionVariant.id
      }
    },

    /*
     * Clears errors hash
     */
    clearErrors() {
      this.errors = {}
    },

    /*
     * Boolean (true/false) that returns if we have an error in a given option.
     * @param {Object} option - the option we want to check if has error.
     */
    hasError(option) {
      return this.errors[option.id] != null
    },

    /*
     * Boolean (true/false) that returns if we have any error.
     */
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },

    /*
     * Check if all options are valid, and return true/false if not valid.
     */
    optionsValidated() {
      if (this.wizardDisplay) {
        return true
      } else {

        let idx = 0

        for (let optionId of Object.keys(this.selected)) {
          let optionVariantId = this.selected[optionId]
          let isRegularOption = this.options.map((o) => o.id).includes(Number(optionId))

          if (isRegularOption && (!optionVariantId || !Number(optionVariantId)) > 0) {
            this.$set(this.errors, optionId, this.options[idx])
          }
          else {
            delete this.errors[optionId]
          }

          idx++
        }

        return !this.hasErrors()
      }
    },

    /*
     * Returns the photos of given product.
     * Takes _optional_ parameter the option_variant_id (color_id)
     * @param {Integer} option_variant_id - the option_variant_id, default is null
     * @return {Array.<Object>} - returns an array of photos
     */
    photos(option_variant_id = null) {
      let requested_variant = option_variant_id
      let photos = this.currentPhotos

      if (option_variant_id == null) {
        for (let option of this.options) {
          if (option.has_images) {
            for (let optionVariant of option.option_variants) {
              if (this.selected[option.id] == optionVariant.id) {
                option_variant_id = optionVariant.id
                break
              }
            }
          }
        }
      }

      if (option_variant_id || this.colorOption == null) {
        for (let color of this.images) {
          if (color.option_variant_id == option_variant_id) {
            // Memorize result to not trigger again the event if photos don't need to change
            if ((!requested_variant && this.option_variant_id != option_variant_id) || this.colors.length == 0) {
              this.$store.dispatch('triggerEvent', 'gy::photos-changed')
              this.option_variant_id = option_variant_id
              this.currentPhotos = color['photos']
            }
            photos = color['photos']
          }
        }
      }
      if (photos.length > 0) {
        return photos
      }
      else if (this.images[0] && this.images[0]['photos']) {
        // If we still don't have photos to show for the incoming color, fallback
        // to the first photos available
        return this.images[0]['photos']
      }
    },

    /*
     * Returns the specific version of given photo .
     * @param {Object} photo - the photo, which must be the result of photos() method.
     * @param {String} version - the version that you wish to display. Versions are passed as props during the creation of app.
     * @return {String} - returns the URL of specific photo
     */
    photoUrl(photo, version = null) {
      if (version) {
        return photo[version]
      } else {
        let key = Object.keys(photo)[0]
        return photo[key]
      }
    },

    /*
     * Matches two arrays based on if they have the same elements. The difference is that it returns true also if
     * one of the elements is null.
     * @param {Array} array1 - the first array to match
     * @param {Array} array2 - the second array to match
     * @return {Boolean} - returns true/false
     */
    matchOptionVariantIdArrays(array1, array2) {
      let result_array = []

      for (let i = 0; i < array1.length; i++) {
        result_array[i] = (array1[i] == array2[i] || array1[i] == null || array2[i] == null)
      }
      let unique_result_set = [...new Set(result_array)]

      return unique_result_set.length == 1 && unique_result_set[0] == true
    },


    /*
     * Calculates the combinations between option variants (color, size etc) based on the available stock.
     * Used to disable/enable options when not available.
     */
    refreshAvailabilities() {
      let quantities   = [0]
      let disabled     = true
      let index        = 0

      this.clearErrors()

      let key_to_compare

      // If there are no options, maxQuantity is just the default stock returned by the api.
      if (this.options.length == 0) {
        this.maxQuantity = this.stocks.default
      } else {
        this.options.forEach((option, index) => {
          for (let optionVariant of option.option_variants) {
            key_to_compare = this.selectedKey.slice(0)

            // For each option that we haven't yet selected
            if (key_to_compare[index] == null || key_to_compare[index] != optionVariant.id) {
              key_to_compare[index] = optionVariant.id
            }

            if ((!option.has_images || this.options.length == 1) && !this.unlimited_policy) {
              optionVariant.disabled = true
            }

            for (let stock of this.formattedStocks) {
              if (this.matchOptionVariantIdArrays(key_to_compare, stock.key) && stock.quantity > 0) {
                optionVariant.disabled = false

                if (this.matchOptionVariantIdArrays(key_to_compare, this.selectedKey)) {
                  quantities.push(stock.quantity)
                }
              }
            }
          }
        })

        this.maxQuantity = (this.unlimited_policy) ? this.stocks.default : this.arrayMax(quantities)
      }


      // Adjust selected quantity, if exceeds the maxQuantity available
      if (this.quantity > this.maxQuantity && this.quantity > 1) {
        this.quantity = this.maxQuantity
      }

      // Preselect option variants
      this.selectOptionVariants()

      setTimeout(() => {
        this.$store.dispatch('triggerEvent', 'gy::option-variant-changed')
      }, 50)
    },

    /*
     * Preselects first options available.
     */
    selectOptionVariants(index = 0) {
      if (this.options.length <= index) return

      let option = this.options[index]
      let selected = this.selected[option.id]

      // If the default option_variant is set for the current option, set is as pre-selected
      // TODO Currently, this functionality only works with `<select>` and `<option>` html markup for option variants. We should continue the implementation for `<ul>` and `<li>` markup (e.g. Access Fashion)
      if (option.default_option_variant_id && typeof selected != "undefined" && selected == null) {
        for (var optionVariant of option.option_variants) {
          if (optionVariant.id == option.default_option_variant_id) {
            for (var optionVariant of option.option_variants) {
              if (optionVariant.id == option.default_option_variant_id) {
                selected = option.default_option_variant_id
              }
            }
          }
        }
      }

      // If we display all colors in catalogue (given colorId), preselect product catalog item color,
      // but only the first time, not every time we try to select a different color
      if (!this.loadOptionsCalled) {
        for (var optionVariant of option.option_variants) {
          if (optionVariant.id == this.colorId){
            selected = optionVariant.id
            break
          }
        }
      }

      // If we don't have selected anything for this option, select one below
      if (selected == null) {
        if (option.has_images && this.params && this.params.option_variant_id) {
          for (var optionVariant of option.option_variants) {
            if (parseInt(this.params.option_variant_id) == optionVariant.id) {
              selected = optionVariant.id
              break
            }
          }
        }
      }

      // Check if existing selected option is available. If its not reset my selection
      if (selected > 0) {
        var ov = this.detect(option.option_variants, selected)
        if (ov && ov.disabled) {
          selected = null
        }
      }

      // Again only for sizes, preselect the first empty option if nothing found above
      if (!option.has_images && selected == null) {
        selected = ""
      }

      // If we have a color, preselect the first option available from option variants
      if (option.has_images && selected == null) {
        selected = (option.option_variants.find(option => !option.disabled) || {}).id
      }

      // Change this.selected if it actually changed to prevent infinite loops from watcher
      if (this.selected[option.id] != selected){
        this.selected[option.id] = selected
      }

      this.selectOptionVariants(index + 1)
    },

    /*
     * Adds to cart the given product, passing also the selected options & quantity if available.
     */
    addToCart($event) {
      let data = { products: [] }

      if (this.wizardDisplay) {
        // We have wizard type display, with table for multiple quantities in the end
        for (let optionVariantRow of this.$refs.optionVariantsTable.$refs.optionVariantRow) {
          if (optionVariantRow.quantity > 0) {
            data["products"].push({
              product_id: this.computedProductId,
              options: optionVariantRow.selected,
              quantity: optionVariantRow.quantity,
              promotion_id: (this.promotion || {}).id
            })
          }
        }
      } else {
        // We have only 1 product to be added in cart
        data["products"].push({
          product_id: this.computedProductId,
          options: this.selected,
          quantity: this.quantity,
          promotion_id: (this.promotion || {}).id,
          gift_list_id: this.giftListId
        })
      }

      if (this.optionsValidated()) {
        this.$http.post(`${this.apiPath}/order`, data).then(response => {
          let $this = this
          $this.addedToCart = true

          let  { message, recently_added } = response.body
          this.$store.state.recentlyAdded = recently_added
          miniCart.loadOrder()

          let optionVariantId = this.selected[(this.colorOption || {}).id]

          let triggerEventObj = {
            type: 'gy::added-to-cart',
            message,
            event: $event,
            optionVariantId
          }

          if (Array.isArray(recently_added) && recently_added.length > 0)
            triggerEventObj.item = recently_added[0]

          this.$store.dispatch('triggerEvent', triggerEventObj)

          setTimeout(() => {
            $this.addedToCart = false
          }, 4200)
        },
        (error) => {
          let message              = error.body.message
          let redirectToCartButton = { title: NiceI18n.t("gift_lists.edit_cart"), handler: () => { window.location.href = location.protocol + '//' + location.host + "/order"; } }
          let okButton             = { default: true, title: 'ΟΚ' }

          this.showModal(message, [redirectToCartButton, okButton])
        })
      } else {
        let $this = this
        $this.clickedWhenDisabled = true
        let errorMessages = []

        for (let key of Object.keys(this.errors)) {
          var option = this.errors[key]
          errorMessages.push(option.title)
        }

        this.$store.dispatch('triggerEvent', { type: 'gy::cannot-add-to-cart', message: errorMessages.join(', ') })

        setTimeout(() => {
          $this.clickedWhenDisabled = false
        }, 800)
      }
    },

    /*
     * Adds to wishlist the given product, passing also the selected options & quantity if available.
     * Depending on if the user is logged in, than a 401 not authorized may be returned, where the
     * gy::user-needs-login event is triggered.
     *
     * Also if the user has already the selected item in wishlist, gy::already-exists-in-wishlist event
     * is triggered and the product is not added again to wishlist.
     */
    addToWishlist() {
      this.$http.post(`${this.apiPath}/wishlist_items`, {
        product_id: this.computedProductId,
        options: this.selected,
        quantity: this.quantity
      }).then(response => {
        wishlist.loadWishlistItems(false)
        this.$store.dispatch('triggerEvent', { type: 'gy::added-to-wishlist', message: response.body.message })
      }, response => {
        // Not authorized
        if (response.status == 401) {
          this.$store.dispatch('setHistory', { component: this, method: 'addToWishList' })
          this.$store.dispatch('triggerEvent', { type: 'gy::user-needs-login', message: response.body.error })
        } else if (response.status == 406) {
          wishlist.loadWishlistItems(false)
          this.$store.dispatch('triggerEvent', { type: 'gy::already-exists-in-wishlist', message: response.body.message })
        }
      })
    },

    refreshSkus() {
      this.sku = {}

      if (this.skus) {
        for (let key of Object.keys(this.skus)) {
          if (key == this.selectedKey.join(",")) {
            this.sku = this.skus[key]
            break
          }
        }
      }
    },

    /*
     * Increases the quantity of the product by 1
     * Used in a -/+ input field for increase/decrease operations
     */
    increaseQuantity() {
      this.quantity = parseInt(this.quantity) + 1
    },

    /*
     * Decreases the quantity of the product by 1
     * Used in a -/+ input field for increase/decrease operations
     */
    decreaseQuantity() {
      this.quantity = parseInt(this.quantity) - 1
    },

    /*
     * Takes an array of numbers, and returns the maximum one.
     * @param {Array} array - For example [1, 6, 7]
     * @return {Number} - the maximum number, for example 7 for above example
     */
    arrayMax(array) {
      return array.reduce((a, b) => {
        return Math.max(a, b)
      })
    },

    /*
     * Implement ruby like detect method. Takes a collecton as param and
     * speficic id and returns the first element of collection with id that matches
     * @param {Array} collection - For example [object, object, object]
     * @param {Number} id - For example 3
     */
    detect(collection, id) {
      return collection.filter((el) =>
        el.id == id
      )[0]
    },

    fetchFromSkuOrProduct(attribute) {
      let value = null

      if (!Utils.checkForEmptyObject(this.sku)) {
        value = this.sku[attribute]

      } else if (Utils.checkForEmptyObject(this.skus) || !this.skuOnlyAttributeIfSkus) {
        value = this.productObject[attribute]
      }

      return value
    },

	  /*
     * If the project has slick this method can be called before changing
     * option variant to remove slick and initialize it again after gy::option-variant-changed
     * is fired.
     * Set the divs that have slick like so: .product-thumbs{ "ref" => "any_name" }
     * Example case used on click listener: "@click" => "unslick(); setSelected(optionVariant)"
    */
    unslick() {
      $.each(this.$refs, function(name,val){
        if($(val).hasClass('slick-initialized')) {
          $(val).slick('unslick');
        }
      })
    },

    selectedOptionVariantForOption(option) {
      let optionVariantId = this.selected[option.id]

      if (optionVariantId) {
        for (let optionVariant of option.option_variants) {
          if (parseInt(optionVariant.id) == parseInt(optionVariantId)) {
            return optionVariant
          }
        }
      }
    },

    isOpened(index) {
      return (index + 1 == this.step)
    },

    isEditable(index) {
      return (index + 1 < this.step)
    },

    canProceedNextStep(option) {
      return (this.selected[option.id] && String(this.selected[option.id]).length > 0)
    },

    proceedStep(option) {
      if (this.canProceedNextStep(option))
        this.step += 1
    },

    editStep(step) {
      this.step = step + 1

      if (this.step < this.wizardOptions.length) {
        this.optionVariantsTableFilled = false
        for (var i = step; i <= this.wizardOptions.length - 1; i++) {
          let option_id = this.wizardOptions[i].id
          this.selected[option_id] = ""
        }
      }
    },

    hasNextStep() {
      return (this.step + 1 <= this.wizardOptions.length)
    },

    sort(optionVariants) {
      return optionVariants.sort((a, b) => {
        return a.title - b.title
      })
    }
  }
})
</script>
